<template>
  <div>
    <h3 class="page-title">ئەزا باشقۇرۇش</h3>
    <el-divider></el-divider>

    <!-- table-box -->
    <div v-if="showList">
      <div class="rtl-right search-box">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-input v-model="filterForm.keyword" @keyup.native.enter="toSearch()" clearable placeholder="ھالقىلىق سۆزنى كىرگۈزۈڭ"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
            <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار  قىلىش </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="table-box" v-if="pageTags.list">
        <el-table
          :data="tableData" 
          border 
          style="width: 100%" 
          v-loading="loading">
          <el-table-column type="expand" label="ئىگە ">
            <template slot-scope="props">
              <el-table :data="props.row.owners" border style="width: 100%">
                <el-table-column prop="ownerable_type" label="تۈرى ">
                  <template slot-scope="scope">
                    <label v-show="scope.row.ownerable_type=='vips'">ئالاھىدە  ئەزا </label>
                    <label v-show="scope.row.ownerable_type=='agents'">ۋاكالەتچى </label>
                    <label v-show="scope.row.ownerable_type=='courses'">دەرس </label>
                    <label v-show="scope.row.ownerable_type=='teachers'">ئوقۇتقۇچى</label>
                  </template>
                </el-table-column>
                <el-table-column prop="ownerable.name_ug" label="ئىسمى "></el-table-column>
                <el-table-column prop="expired_at" label="ئاخىرلىشىش  ۋاقتى "></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="image" label="باش سۈرەت" width="100">
            <template slot-scope="scope">
              <img :src="scope.row.avatar" class="table-img">
            </template>
          </el-table-column>
          <el-table-column prop="name" label="ئىسمى"></el-table-column>
          <el-table-column prop="uid" label="uid"></el-table-column>
          <el-table-column prop="created_at" label="كىرگەن ۋاقىت"></el-table-column>
          <el-table-column label="مەشغۇلات" prop="dosome" width="300">
            <template slot-scope="scope">
              <!-- <el-button
                size="mini"
                type="primary"
                @click="handleQrcode(scope.row)">ئىككىلك كودى</el-button> -->
              <el-button
                size="mini"
                type="success"
                @click="handleOwner(scope.row)">ئىگە  تەقسىملەش </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="total">
        </el-pagination> 
      </div>
    </div>

    <!-- qrcode -->
    <div v-if="!showList">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-right" @click="backToList">قايتىش</el-button>
      </el-row>
      <el-divider>{{curItem.name}}</el-divider>
      <div v-loading="qrcodeLoading">
        <el-row :gutter="10" style="direction:rtl;">
          <el-col :span="8" v-for="(item,index) in qrcodeData" :key="index">
            <el-card class="box-card">
              <div slot="header" class="clearfix" style="overflow:hidden;">
                <span style="float:right;">{{item.qrcodeable.name_ug}}</span>
                <el-button style="padding: 3px 0;color:#ff5151;float:left;" type="text" @click="handleDelete(item)">删除二维码</el-button>
              </div>
              <img :src="item.qr_code" class="qrcode-img" />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- add owner -->
    <el-dialog
      title="ئىگە  تەقسىملەش "
      :visible.sync="dialogVisible"
      width="35%">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="ئەزا ئىسمى">
            <span class="cur-text">{{curUser.name}}</span>
          </el-form-item>

          <el-form-item label="ئىگە تۈرى" prop="ownerable_type">
            <el-row>
              <el-col :span="8">
                <label class="choise-tip" v-if="ruleForm.ownerable_type == ''">(ئاۋۋال تۈر تاللاڭ)</label>
                <el-button type="success" plain v-if="ruleForm.ownerable_type == 'vips'" @click="showVips">ئالاھىدە ئەزا تاللاش</el-button>
                <el-button type="success" plain v-if="ruleForm.ownerable_type == 'agents'" @click="showAgents">ۋاكالەتچى تاللاش</el-button>
                <el-button type="success" plain v-if="ruleForm.ownerable_type == 'courses'" @click="showCourses">دەرس تاللاش</el-button>
                <el-button type="success" plain v-if="ruleForm.ownerable_type == 'teacherss'" @click="showTeachers">ئوقۇتقۇچى تاللاش</el-button>
              </el-col>
              <el-col :span="ruleForm.ownerable_type == 'teachers' ? 24 : 16">
                <el-select v-model="ruleForm.ownerable_type" class="rtl-right" filterable clearable placeholder="ئىگە تۈرى">
                  <el-option
                    v-for="item in ownerableTypeList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="تاللىغان ئالاھىدە ئەزا " v-if="ruleForm.ownerable_type == 'vips'">
            <p class="cur-text">{{curVip.name_ug}}</p>
          </el-form-item>
          <el-form-item label="تاللىغان ۋاكالەتچى" v-if="ruleForm.ownerable_type == 'agents'">
            <p class="cur-text">{{curAgent.name_ug}}</p>
          </el-form-item>
          <el-form-item label="تاللىغان دەرس" v-if="ruleForm.ownerable_type == 'courses'">
            <p class="cur-text">{{curCourse.name_ug}}</p>
          </el-form-item>
          <el-form-item label="تاللىغان ئوقۇتقۇچى" v-if="ruleForm.ownerable_type == 'teacherss'">
            <p class="cur-text">{{curTeacher.name_ug}}</p>
          </el-form-item>

          <el-form-item label="ئاخىرلىشىش ۋاقتى" prop="expired_at">
            <el-date-picker v-model="ruleForm.expired_at" type="date" value-format="yyyy-MM-dd" placeholder="ئاخىرلىشىش ۋاقتى"></el-date-picker>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form" v-show="1==0">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="dialogVisible = false">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- vips -->
    <el-drawer
      title="ئالاھىدە ئەزا تاللاش"
      size="50%"
      :visible.sync="vipsDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="vipTableData" border style="width: 100%">
          <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
          <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseVip(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <!-- agent -->
    <el-drawer
      title="ۋاكالەتچى تاللاش"
      size="50%"
      :visible.sync="agentsDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="agentTableData" border style="width: 100%">
          <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseAgent(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeAgent"
          @current-change="handleCurrentChangeAgent"
          background
          :current-page="agent_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="agent_total">
        </el-pagination>
      </div>
    </el-drawer>

    <!-- course -->
    <el-drawer
      title="دەرس تاللاش"
      size="50%"
      :visible.sync="coursesDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="courseTableData" border style="width: 100%">
          <el-table-column prop="name_ug" label=" دەرس (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label=" دەرس (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseCourse(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeCourse"
          @current-change="handleCurrentChangeCourse"
          background
          :current-page="course_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="course_total">
        </el-pagination>
      </div>
    </el-drawer>

    <!-- teacher -->
    <el-drawer
      title="ئوقۇتقۇچى تاللاش"
      size="50%"
      :visible.sync="teachersDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="teacherTableData" border style="width: 100%">
          <el-table-column prop="name_ug" label=" ئوقۇتقۇچى (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label=" ئوقۇتقۇچى (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseTeacher(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

  </div>
</template>

<style lang="scss" scoped>
  .table-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #EBEEF5;
  }
  .qrcode-img{
    width: 95%;
    height: 450px;
  }
  .el-date-editor{
    width: 100%;
  }
  .choise-tip{
    position: relative;
    top: 10px;
    right: 10px;
    color: #E6A23C;
  }
  .drawer-table-box{
    padding: 0 10px;
  }
  .cur-text{
    position: relative;
    top: 10px;
    color: #409EFF;
  }
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
        pageTags:{},
        curItem:{},
        showList:true,
        qrcodeLoading:false,
        qrcodeData:[],
        dialogVisible:false,
        ruleForm:{
          ownerable_type:"",
          expired_at:"",
          status:"1"
        },
        filterForm:{
          keyword:""
        },
        curUser:{},
        ownerableTypeList:[
          {
            name:"ئالاھىدە ئەزا",
            value:"vips"
          },
          {
            name:"ۋاكالەتچى",
            value:"agents"
          },
          {
            name:"دەرس",
            value:"courses"
          },
          {
            name:"ئوقۇتقۇچى",
            value:"teachers"
          }
        ],
        curVip:{},
        curAgent:{},
        curCourse:{},
        curTeacher:{},
        vipsDrawer:false,
        vipTableData:[],
        agentsDrawer:false,
        agentTableData:[],
        agent_per_page:10,
        agent_current_page:1,
        agent_last_page:0,
        agent_total:0,
        coursesDrawer:false,
        courseTableData:[],
        course_per_page:10,
        course_current_page:1,
        course_last_page:0,
        course_total:0,
        teachersDrawer:false,
        teacherTableData:[]
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/user",{
          keyword:self.filterForm.keyword,
          page:self.current_page,
          per_page:self.per_page
        }).then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
            self.total = response.data.meta.total;
            self.current_page = response.data.meta.current_page;
            self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      handleQrcode(row){
        self.curItem = row;
        self.showList = false;

        self.qrcodeLoading = true;
        self.$fetch("admin/user/"+self.curItem.id+"/qrcode").then(response => {
          if (response.status == 200) {
            self.qrcodeData = response.data.data;
          } else{
            console.log(response.message);
          }
          self.qrcodeLoading = false;
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.qrcodeLoading = false;
        });
      },
      handleDelete(item) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/user/qrcode/'+item.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      handleOwner(row){
        self.curUser = row;
        self.dialogVisible = true;
      },
      resetForm(formName){
        self.$refs[formName].resetFields();
        self.ruleForm = {
          expired_at:"",
          ownerable_type:"",
          status: "1"
        }
      },
      submitForm(){
        var myData = {
          user_id:self.curUser.id,
          ownerable_type:self.ruleForm.ownerable_type,
          expired_at:self.ruleForm.expired_at,
          status:self.ruleForm.status
        }
        if(self.ruleForm.ownerable_type == "vips"){
          myData.ownerable_id = self.curVip.id;
        }else if(self.ruleForm.ownerable_type == "agents"){
          myData.ownerable_id = self.curAgent.id;
        }else if(self.ruleForm.ownerable_type == "courses"){
          myData.ownerable_id = self.curCourse.id;
        }else if(self.ruleForm.ownerable_type == "teachers"){
          // myData.ownerable_id = self.curTeacher.id;
        }

        self.$post("admin/user/owner",myData).then(response => {
          if (response.status == 201) {
            self.getList();
            self.$message({
              message: response.data.message,
              type: "success",
              duration: 1200
            });
            self.dialogVisible = false;
            self.resetForm('ruleForm');
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getVipsList(){
        self.$fetch("admin/vip").then(response => {
          if (response.status == 200) {
           self.vipTableData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getAgentsList(){
        self.$fetch("admin/agent",{
          page : self.agent_current_page,
          per_page : self.agent_per_page
        }).then(response => {
          if (response.status == 200) {
           self.agentTableData = response.data.data;
           self.agent_total = response.data.meta.total;
           self.agent_current_page = response.data.meta.current_page;
           self.agent_last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getCoursesList(){
        self.$fetch("admin/course",{
          page : self.course_current_page,
          per_page : self.course_per_page
        }).then(response => {
          if (response.status == 200) {
           self.courseTableData = response.data.data;
           self.course_total = response.data.meta.total;
           self.course_current_page = response.data.meta.current_page;
           self.course_last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getTeachersList(){
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
           self.teacherTableData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      showVips(){
        self.getVipsList();
        self.vipsDrawer = true;
      },
      confirmChoiseVip(row){
        self.curVip = row;
        self.vipsDrawer = false;
      },
      showAgents(){
        self.getAgentsList();
        self.agentsDrawer = true;
      },
      confirmChoiseAgent(row){
        self.curAgent = row;
        self.agentsDrawer = false;
      },
      handleSizeChangeAgent(val){
        self.agent_per_page = val;
        self.getAgentsList();
      },
      handleCurrentChangeAgent(val){
        self.agent_current_page = val;
        self.getAgentsList();
      },
      showCourses(){
        self.getCoursesList();
        self.coursesDrawer = true;
      },
      confirmChoiseCourse(row){
        self.curCourse = row;
        self.coursesDrawer = false;
      },
      handleSizeChangeCourse(val){
        self.course_per_page = val;
        self.getCoursesList();
      },
      handleCurrentChangeCourse(val){
        self.course_current_page = val;
        self.getCoursesList();
      },
      showTeachers(){
        self.getTeachersList();
        self.teachersDrawer = true;
      },
      confirmChoiseTeacher(row){
        self.curTeacher = row;
        self.teachersDrawer = false;
      },
      backToList(){
        self.curItem = {};
        self.showList = true;
      },
      toSearch(){
        self.current_page = 1;
        self.getList();
      },
      toClear(){
        self.filterForm = {
          keyword:""
        }
        self.toSearch();
      }
    },
    deactivated(){
      self.backToList();
    }
  };
</script>
